import * as React from "react"

import { Link } from 'gatsby'


import Layout from "../components/Layout"
import Seo from "../components/Seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404" />
    <div className="w-100 min-h-screen-fill-content into relative pt-20 py-20">
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-primary-700 text-center font-black">
            <span className="mt-5 block font-black emb-2xl font-emberly-extra-bold relative -left-[2px]">404</span>
            <span className="mt-5 block russolo-lg">
                You could check out my 
                <Link 
                    to="/work" 
                    className={`hover:text-pop duration-300 mx-1 russolo-lg cursor-pointer decoration-1 hover:decoration-1 underline underline-offset-[6px] hover:underline text-primary-700`}
                >
                    work
                </Link>
                instead
            </span>
        </div>
    </div>
  </Layout>
)

export const Head = () => <Seo title="404: Not Found" />

export default NotFoundPage
